// Slider
.slider-front {
  height: calc(100vh - 80px);
  //@media screen and (max-width: $screen-sm) {
  //  height: auto;
  //}
  > .container {
    height: 100%;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: $screen-sm) {
      padding-bottom: 30px;
    }
    .row {
      height: 100%;
      .region-slider {
        height: 100%;
        > .block {
          height: 100%;
          > .content {
            height: 100%;
          }
        }
      }
    }
  }

  &-nav {
    text-align: right;
    margin: 0 15px 30px;
    @media screen and (max-width: $screen-xs) {
      display: none;
    }
    .owl-prev {
      display: inline-block;
      padding-left: 32px;
      margin-right: 15px;
      text-transform: uppercase;
      color: $white;
      background-image: url("../img/ic-arrow-left-24-x-24-gold.svg");
      background-position: left center;
      background-repeat: no-repeat;
      @include textfont;
      cursor: pointer;
      user-select: none;
      &:hover {
        color: $white;
        background-image: url("../img/ic-arrow-left-24-x-24-white.svg");
      }
    }
    .owl-next {
      @extend .owl-prev;
      padding-right: 32px;
      padding-left: 0;
      margin-right: 0;
      margin-left: 15px;
      background-image: url("../img/ic-arrow-right-24-x-24-gold.svg");
      background-position: right center;
      &:hover {
        background-image: url("../img/ic-arrow-right-24-x-24-white.svg");
      }
    }
  }

  &-dots {
    z-index: 1;
    position: absolute;
    bottom: 250px;
    left: 46px;
    width: 16px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $screen-xs) {
      display: none;
    }
    @media screen and (max-width: $screen-sm) {
      bottom: 340px;
    }
    &:after,
    &:before {
      width: 1px;
      background-color: rgba($darklight, 0.8);
      height: 50px;
      content: "";
      display: block;
      position: absolute;
      left: 7px;
      @media screen and (max-width: $screen-sm) {
        height: 120px;
      }
    }
    &:before {
      top: -64px;
      @media screen and (max-width: $screen-sm) {
        top: -134px;
      }
    }
    &:after {
      bottom: -64px;
      @media screen and (max-width: $screen-sm) {
        bottom: -134px;
      }
    }

    .owl-dot {
      margin-top: 7px;
      margin-bottom: 7px;
      width: 16px;
      height: 16px;
      display: block;
      background-color: $darklight;
      cursor: pointer;
      &:hover {
        background-color: $gold-hover;
      }
      &.active {
        background-color: $gold;
        cursor: default;
      }
    }
  }

}

.view-slider {
  .view-header {
    position: absolute;
    right: 15px;
    margin-top: -25px;
  }
  .view-content {
    width: 100%;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    overflow: hidden;
    @media screen and (max-width: $screen-xs) {
      top: 50%;
    }
    &-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .slide-row {
      padding-left: 15px;
      padding-right: 15px;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: $screen-sm) {
        padding-left: 80px;
        padding-right: 15px;
        padding-top: 30px;
      }
      @media screen and (max-width: $screen-xs) {
        padding-top: 0;
      }

      .slide-title {
        flex-basis: 100%;
        h2 {
          text-align: right;
          font-size: 3.6em;
          margin: 0;
          @media screen and (max-width: $screen-md) {
            font-size: 2.8em;
          }
          @media screen and (max-width: $screen-xs) {
            font-size: 2.2em;
            line-height: 1;
          }
          a {
            color: $white;
            text-decoration: none;
            &:hover,
            &:focus {
              text-decoration: none;
            }
          }
        }
      }
      .slide-desc {
        margin-top: 15px;
        margin-bottom: 40px;
        text-align: right;
        flex-basis: 100%;
        @media screen and (max-width: $screen-sm) {
          margin-bottom: 15px;
          line-height: 1.2;
          font-size: 0.925em;
        }
      }
      .slide-link-wrapper {
        display: inline-block;
        float: right;
        .slide-link {
          @extend .button;
          margin-left: 30px;
          @media screen and (max-width: $screen-sm) {
            margin-bottom: 15px;
          }
          &-2 {
            background-color: transparent;
            border: 4px solid rgba($white, 0.5);
            line-height: 46px;
            &:after {
              display: none;
            }
            &:hover,
            &:focus {
              background-color: transparent;
              border-color: $gold-hover;
              color: $gold-hover;
            }
          }
        }
      }

    }
  }
}
