// Product

.product-wrapper {
  background-color: $dark;
  color: $white;
  background-image: url("../img/woodtexture_dark.png");
  background-position: center;
  background-repeat: repeat;
  background-size: 30%;
  position: relative;
  @media screen and (max-width: $screen-lg) {
    background-size: 50%;
  }
  @media screen and (max-width: $screen-sm) {
    background-size: 90%;
  }
  .header {
    a {
      color: $white;
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
  .breadcrumb {
    margin-top: 30px;
    a:hover {
      color: $white;
    }
  }
}

.product-card {
  padding-bottom: 40px;
  .breadcrumb {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  a {
    color: $gold;
    &:hover,
    &:focus {
      color: $white;
    }
  }
  .product-nav,
  .catalog-nav {
    margin-top: 30px;
  }
}

.product {

  &-image {
    .product-nav {
      .owl-prev,
      .owl-next {
        color: $white;
        &:hover {
          color: $gold;
        }
      }
    }
    &-slider {
      height: 480px;
      margin-top: 10px;
      .product-slide {
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .owl-stage-outer,
      .owl-stage,
      .owl-item {
        height: 100%;
      }
    }
  }

  &-main {
    @media screen and (max-width: $screen-md) {
      margin-top: 30px;
    }
  }
  &-title {
    color: $gold;
    margin-top: 0;
  }
  &-body {
    margin-bottom: 15px;
  }
  &-designers {
    margin-bottom: 30px;
  }

  &-properties {
    color: $darklight;
    label {
      display: block;
      text-align: left;
      float: left;
    }
    span {
      display: block;
      text-align: right;
    }
  }
  &-sizes,
  &-material,
  &-cover,
  &-items,
  &-prices {
    display: flex;
    flex-wrap: nowrap;
    padding: 5px 0;
    border-bottom: 1px dashed rgba($darklight, 0.3);
    label {
      flex-basis: 40%;
    }
    span {
      flex-basis: 60%;
    }
  }

  &-sizes {}
  &-material {}
  &-cover {}
  &-items {}
  &-prices {
    border: none;
  }

  &-add {
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    @media screen and (max-width: $screen-xs) {
      flex-wrap: wrap;
    }
  }

  &-image-pages {
    display: flex;
    flex-wrap: nowrap;
    @media screen and (max-width: $screen-lg) {
      position: relative;
      top: -70px;
    }
    @media screen and (max-width: $screen-md) {
      display: none;
    }
    .product-page {
      border: 2px solid $dark;
      width: 100px;
      height: 72px;
      display: block;
      margin: 0 5px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: border 0.8s;
      &:hover,
      &.active {
        border-color: $white;
      }
    }
  }

  &-buttons {
    text-align: right;
    margin-top: 15px;
    @media screen and (max-width: $screen-sm) {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    @media screen and (max-width: $screen-xs) {
      margin-top: 30px;
      flex-wrap: wrap;
      justify-content: center;
    }
    a {
      color: $white;
      margin-left: 15px;
      margin-bottom: 10px;
      @media screen and (max-width: $screen-sm) {
        flex-basis: 48%;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        &:first-child {
          margin-left: 0;
          margin-right: 15px;
          @media screen and (max-width: $screen-xs) {
            margin-right: 0 !important;
          }
        }
      }
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 15px;
      }
      &:first-child {
        padding-left: 90px;
        padding-right: 90px;
      }
    }
  }

}


// Materials and Decorates

.materials-decorates {
  padding-top: 60px;
  padding-bottom: 60px;
  @media screen and (max-width: $screen-sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &-materials {}
  &-decorates {
    margin-top: 30px;
    @media screen and (max-width: $screen-xs) {
    display: flex;
    flex-wrap: wrap;
      .decorates-slider {
        order: 1;
        width: 100%;
      }
    }
  }
}

.materials,
.decorates {
  @media screen and (max-width: $screen-sm) {
    text-align: left !important;
  }
  &-title {
    margin-top: 0;
    @media screen and (max-width: $screen-sm) {
      margin-top: 30px;
    }
  }

  &-slider {
    display: flex;
    flex-wrap: wrap;
    .material,
    .decorate {
      border: 1px solid rgba($darklight, 0.3);
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 128px;
      margin: 3px;
      &-image {}
      &-name {
        flex-grow: 1;
        text-align: center;
        color: $darklight;
        font-size: 0.9em;
        line-height: 1.1;
        padding: 5px 10px;
        width: auto;
        min-height: 3em;
        background-color: $white;
      }
    }
  }
  &-nav {
    margin: 20px 5px 15px 5px;
    width: 100%;
    @media screen and (max-width: $screen-sm) {
      text-align: right !important;
    }
    //&.disabled {
    //  .owl-prev,
    //  .owl-next {
    //    cursor: default;
    //  }
    //}
    .owl-prev {
      display: inline-block;
      background-image: url("../img/ic-arrow-left-24-x-24-white.svg");
      background-repeat: no-repeat;
      background-position: center;
      height: 32px;
      width: 60px;
      background-color: rgba($darklight, 0.3);
      cursor: pointer;
      margin-right: 1px;
      &:hover {
        background-image: url("../img/ic-arrow-left-24-x-24-gold.svg");
      }
    }
    .owl-next {
      @extend .owl-prev;
      background-image: url("../img/ic-arrow-right-24-x-24-white.svg");
      margin-right: 0;
      &:hover {
        background-image: url("../img/ic-arrow-right-24-x-24-gold.svg");
      }
    }
  }
}
