// Sidebars
$sidebar-padding: 50px;
$sidebar-zindex: 99;

.sidebar-expander {
  height: $header-height;
  width: $header-height;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: -($header-height / 2.35);
  z-index: $sidebar-zindex - 1;
  cursor: pointer;
  background-size: 26px;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-position 0.2s, background-size 0.2s;
  @media screen and (max-width: $screen-xl-max) {
    display: flex;
    background-color: rgba($white, 0.4);
    @include br50;
  }
  &:hover {
    background-position: right 23px center;
    background-size: 28px;
  }
  &.active {
    z-index: 100;
    transition-delay: 0.2s;
    background-color: transparent;
    background-image: url("../img/ic-sidebar-32-x-32-darklight.svg") !important;
    &:hover {
      background-image: url("../img/ic-sidebar-32-x-32-white.svg") !important;
    }
  }
}

.sidebar-categories {
  left: -330px;
  top: 0;
  width: 17%;
  height: 100vh;
  position: fixed;
  z-index: $sidebar-zindex;
  transition: left 0.3s ease-out, visibility 0.3s;
  visibility: hidden;
  overflow: hidden;
  @media screen and (max-width: $screen-xl-max) {
    left: -330px;
    visibility: hidden;
  }
  @media screen and (min-width: $screen-xl) {
    left: 0;
    visibility: visible;
  }
  &-wrapper {
    overflow: visible;
    margin-right: 6px;
    color: $white;
    background-color: $dark;
    background-image: url("../img/woodtexture_dark.png");
    background-position: top left;
    background-repeat: repeat;
    background-size: 200%;
    height: 100%;
  }
  &.active {
    left: 0;
    visibility: visible;
    //@include bs;
  }
  @media screen and (min-width: $screen-xxl) {
    width: 17%;
  }
  @media screen and (min-width: $screen-xl) {
    width: 13.75%;
  }
  @media screen and (max-width: $screen-xl-max) {
    width: 300px;
  }
}
body.admin-menu {
  .sidebar-expander,
  .sidebar-categories {
    top: 29px;
  }
}

.sidebar {
  &-logo {
    height: $header-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: $sidebar-padding;
    padding-right: $sidebar-padding;
    a {
      width: 100%;
    }
    img {
      text-align: left;
    }
    @media screen and (min-width: $screen-xxl) {
      padding-left: $sidebar-padding;
      padding-right: $sidebar-padding;
    }
    @media screen and (min-width: $screen-xl) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-slogan {
    color: $gold;
    font-size: 0.6em;
    font-style: italic;
    margin-top: -20px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1.1;
    text-transform: lowercase;
    @media screen and (min-width: $screen-xxl) {
      padding-left: $sidebar-padding;
      padding-right: $sidebar-padding;
    }
    @media screen and (min-width: $screen-xl) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-menu {
    padding: 15px $sidebar-padding 30px;
    position: absolute;
    height: calc(100vh - 109px);
    overflow-y: scroll;
    overflow-x: hidden;
    width: calc(100% + 17px);
    @media screen and (min-width: $screen-xxl) {
      padding-left: $sidebar-padding;
      padding-right: $sidebar-padding;
    }
    @media screen and (min-width: $screen-xl) {
      padding-left: 20px;
      padding-right: 20px;
    }
    &-wrapper {}
    ul {
      padding: 0;
      margin: 0;
      > li {
        padding: 0;
        margin: 0 0 30px 0;
        list-style: none;
        a {
          text-transform: none;
          text-decoration: none;
          color: $gold;
          font-size: 1.6em;
          line-height: 1;
          @include headfont;
          &.active {
            color: $white;
            &:after {
              content: "";
              width: 43px;
              height: 32px;
              position: absolute;
              background-image: url("../img/sidebar-menu-item-active.svg");
              background-repeat: no-repeat;
              background-position: center;
              right: 0;
              z-index: 10;
              @media screen and (-webkit-min-device-pixel-ratio:0) {
                margin-right: 17px;
              }
            }
          }
          &:hover,
          &:focus {
            color: $gold-hover;
          }
        }
        > ul {
          padding: 0;
          margin: 5px 0 0;
          > li {
            padding: 0;
            margin: 0;
            list-style: none;
            a {
              line-height: inherit;
              color: $darklight;
              @include textfontb;
              font-size: 0.8em;
              text-transform: uppercase;
              &.active-trail,
              &.active,
              &:hover,
              &:focus {
                color: $white;
              }
              &.active {
                &:after {
                  content: "";
                  width: 43px;
                  height: 32px;
                  position: absolute;
                  background-image: url("../img/sidebar-menu-item-active.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  right: 0;
                  z-index: 10;
                  @media screen and (-webkit-min-device-pixel-ratio:0) {
                    margin-right: 17px;
                  }
                }
              }
            }
          }
        }

      }
    }
  }

  // Sidebar for Contacts page
  &-contacts {
    &-title {
      @include headfont;
      @include bigtext;
      color: $gold;
      margin-bottom: 10px;
    }
    &-item {
      padding-bottom: 15px;
      margin-bottom: 10px;
      margin-top: 10px;
      position: relative;
      &:after {
        content: "";
        width: 72px;
        height: 2px;
        background-color: $darklight;
        display: block;
        position: absolute;
        bottom: 0;
      }
      &:last-child:after {
        display: none;
      }
      a {
        @include textfont;
        color: $darklight;
        text-decoration: none;
        @media screen and (max-width: $screen-sm) {
          font-size: 14px;
        }
        &:hover {
          color: $white;
          text-decoration: underline;
        }
        &.mPS2id-highlight.mPS2id-highlight-first {
          color: $white;
          text-decoration: none;
          cursor: default;
          &:after {
            content: "";
            width: 43px;
            height: 32px;
            position: absolute;
            background-image: url("../img/sidebar-menu-item-active.svg");
            background-repeat: no-repeat;
            background-position: center;
            right: -50px;
            top: 0; //30%;
          }
        }
      }
    }
  }

  &-socials {
    a {
      margin-right: 10px;
    }
  }

}
