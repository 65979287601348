// Portfolio

.portfolio {
  padding-top: 60px;
  padding-bottom: 250px;
  @media screen and (max-width: $screen-sm) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &-wrapper {
    min-height: 80vh;
    background-color: $dark;
    background-repeat: repeat, no-repeat;
    background-position: center;
    background-size: auto, cover;
    background-image: linear-gradient(to bottom, rgba($black, 0.3), rgba($black, 0.3)), url("../img/pages-bg-3.jpg");
    color: $white;
    @media screen and (max-width: $screen-md) {
      background-position: top right 20%;
    }
    @media screen and (max-width: $screen-sm) {
      min-height: auto;
      background-size: auto, 200%;
    }
    @media screen and (max-width: $screen-xs) {
      background-size: auto, cover;
    }
  }

  &-title {
    color: $gold;
    font-size: 3.6em;
    @media screen and (max-width: $screen-sm) {
      font-size: 3.2em;
      &:after { display: none; }
    }
    &:after {
      content: "";
      width: 140px;
      height: 2px;
      display: block;
      background-color: $gold;
      margin-top: 10px;
    }
  }

}

.portfolios {

  .view-portfolio {

    .view-empty {
      text-align: center;
      color: $darklight;
      font-style: italic;
      font-size: 1.2em;
      padding-top: 30px;
      padding-bottom: 80px;
    }

    .view-filters {
      position: relative;
      margin-top: -220px;
      background-image: url("../img/woodtexture_light.png");
      background-position: top left;
      background-size: 35%;
      background-repeat: repeat;
      color: $dark;
      background-color: $whited;
      padding: 15px 0;
      @include bsf;
      margin-left: 15px;
      margin-right: 15px;
      @media screen and (max-width: $screen-sm) {
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 0;
      }
      @media screen and (max-width: $screen-xs) {
        margin-left: 0;
        margin-right: 0;
      }
      .views-exposed-widgets {
        position: relative;
        .views-widget-filter-field_product_category_tid {
          padding: 0;
          width: 100%;

          label[for="edit-field-product-category-tid"] {
            line-height: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            @include textfontb;
            margin-bottom: 15px;
            margin-left: 30px;
            margin-right: 30px;
            @media screen and (max-width: $screen-sm) {
              line-height: 1.2;
              margin-left: 20px;
              margin-right: 20px;
              padding-right: 140px;
            }
            @media screen and (max-width: $screen-xs) {
              line-height: 1;
              padding-right: 40px;
            }
            &:before {
              content: "";
              width: 32px;
              min-width: 32px;
              height: 32px;
              display: inline-block;
              margin-right: 15px;
              background-image: url("../img/ic-filter-32-x-32.svg");
              background-repeat: no-repeat;
              background-size: 28px;
              background-position: center;
            }
          }

          .views-widget {
            padding-top: 10px;
            border-top: 1px solid rgba($darklight, 0.3);
            .form-checkboxes {
              padding-left: 30px;
              padding-right: 30px;

              // Выбрать все
              a.bef-toggle {
                position: absolute;
                top: 5px;
                right: 40px;
                display: flex;
                align-items: center;
                @media screen and (max-width: $screen-xs) {
                  text-indent: 9999em;
                  right: 15px;
                  display: inline;
                }
                &:focus {
                  color: $dark;
                }
                &:before {
                  content: "";
                  display: inline-block;
                  margin-right: 10px;
                  width: 24px;
                  height: 24px;
                  background-image: url("../img/ic-filter-confirm-24-24.svg");
                  background-repeat: no-repeat;
                  background-size: 24px;
                  background-position: center;
                }
                &.clear:before {
                  background-image: url("../img/ic-filter-clear-24-24.svg");
                }
              }

              // Первый уровень
              ul.bef-tree {
                display: flex;
                padding: 0;
                margin: 0;
                @media screen and (max-width: $screen-xs) {
                  flex-wrap: wrap;
                }
                > li {
                  padding: 0 10px;
                  margin: 0;
                  list-style: none;
                  flex-basis: 25%;
                  @media screen and (max-width: $screen-md) {
                    flex-basis: 50%;
                    margin-top: 0;
                    margin-bottom: 15px;
                  }
                  @media screen and (max-width: $screen-sm) {
                    flex-basis: 100%;
                  }
                  .form-item {
                    .form-checkboxes {
                      display: none;
                    }
                    label.option {
                      margin: 5px 0 10px;
                      display: flex;
                      align-items: center;
                      color: $dark;
                      cursor: default;
                      @include headfonti;
                      @include bigtext;
                    }
                  }

                  // Второй уровень
                  > ul {
                    padding: 0;
                    margin: 0;
                    > li {
                      padding: 0;
                      margin: 0;
                      list-style: none;
                      .form-item {
                        height: auto;
                        line-height: 1;
                        &.highlight {
                          .form-checkboxes[checked='checked']:not(:checked) + label.option {
                            color: $dark;
                            &:before {
                              background-image: url("../img/unchecked.svg");
                            }
                          }
                          .form-checkboxes:checked + label.option {
                            color: $gold;
                            &:before {
                              background-image: url("../img/checked.svg");
                            }
                          }
                        }
                        .form-checkboxes {
                          display: none;
                          &[checked='checked'] + label.option,
                          &[checked='checked']:checked + label.option,
                          &:checked + label.option {
                            color: $gold;
                            &:before {
                              background-image: url("../img/checked.svg");
                            }
                          }
                        }
                        label.option {
                          margin: 5px 0;
                          display: flex;
                          align-items: center;
                          color: $dark;
                          cursor: pointer;
                          @include textfont;
                          font-size: 0.95em;
                          user-select: none;
                          line-height: 1;
                          &:hover {
                            color: $gold;
                          }
                          &:before {
                            display: inline-block;
                            content: "";
                            width: 24px;
                            height: 24px;
                            background-image: url("../img/unchecked.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 24px;
                            margin-right: 15px;
                            min-width: 24px;
                          }
                        }
                      }
                    }
                  }

                }
              }
            }
          }
        }
        .views-submit-button {
          padding: 0;
          position: absolute;
          top: 40%;
          right: 40px;
          @media screen and (max-width: $screen-md) {
            top: 90%;
          }
          //@media screen and (max-width: $screen-sm) {
          //  top: 11%;
          //}
          @media screen and (max-width: $screen-sm) {
            position: relative;
            right: inherit;
            top: inherit;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            display: flex;
            justify-content: center;
            margin-top: 10px;
          }
          .form-submit {
            margin-top: 0;
            font-size: 0.825em;
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }

    .view-content {
      margin-top: 30px;
      padding-bottom: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .portfolio-item {
        height: 240px;
        margin: 0 1.5% 15px 0;
        position: relative;
        transition: filter 0.4s;
        @media screen and (min-width: $screen-xl-min) {
          margin-right: 1%;
        }
        &:hover {
          cursor: pointer;
          filter: brightness(1.15);
          &:after {
            filter: brightness(0.85);
          }
          .portfolio-item-title {
            bottom: 0;
            top: initial;
            visibility: visible;
            text-shadow: 0 0 4px rgba(black, 0.4);
          }
        }
        &-title {
          color: $white;
          position: absolute;
          top: 100%;
          width: 100%;
          font-size: 1.2em;
          padding: 12px 15px;
          @include headfont;
          background: linear-gradient(to top, rgba(black, 0.4), transparent);
          line-height: 1.1;
          visibility: hidden;
        }

        &.superbox-last {
          margin-right: 0;
        }
        &-wrapper {
          display: block;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          position: absolute;
        }
      }
    }

  }


}
