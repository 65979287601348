// Categories

.categories {
  padding-bottom: 80px;
  @media screen and (max-width: $screen-md) {
    .container {
      width: 100%;
    }
  }
  @media screen and (max-width: $screen-sm) {
    padding-bottom: 0;
  }
  &-items {
    @media screen and (max-width: $screen-md) {
      @include nopaddings;
    }
  }
}

.category {
  display: flex;
  flex-wrap: nowrap;
  @media screen and (max-width: $screen-md) {
    flex-wrap: wrap;
  }
  &:nth-child(even) {
    .category-image {
      order: 0;
      @media screen and (max-width: $screen-md) {
        order: inherit;
      }
    }
    .category-item {
      order: -1;
      @media screen and (max-width: $screen-md) {
        order: inherit;
      }
      .category-name,
      .category-title,
      .category-desc,
      .category-links {
        text-align: right;
        @media screen and (max-width: $screen-sm) {
          text-align: left;
        }
      }
      .category-name:before {
        right: -55%;
        left: inherit;
        @media screen and (max-width: $screen-md) {
          right: -15%;
        }
        @media screen and (max-width: $screen-sm) {
          right: initial;
          left: -15%;
        }
      }
      .category-subcategories {
        text-align: right;
        a {
          margin: 0 0 10px 10px;
        }
      }
    }
  }
  &-name {
    a {
      color: $gold;
      text-decoration: none;
      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }
  &-image {
    flex-basis: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: $screen-md) {
      flex-basis: 100%;
      min-height: 360px;
    }
    @media screen and (max-width: $screen-sm) {
      min-height: 496px;
    }
  }
  &-item {
    flex-basis: 50%;
    padding: 40px 60px;
    background-image: url("../img/woodtexture_light.png");
    background-position: top center;
    background-repeat: repeat;
    background-size: 60%;
    @media screen and (max-width: $screen-md) {
      flex-basis: 100%;
    }
    @media screen and (max-width: $screen-xs) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &-name {
    color: $gold;
    @include textfontb;
    font-size: 1.3em;
    text-transform: lowercase;
    position: relative;
    &:before {
      content: "";
      width: 50%;
      height: 1px;
      background-color: $gold;
      position: absolute;
      top: 50%;
      left: -55%;
      transform: translateY(-50%);
      @media screen and (max-width: $screen-md) {
        transform: rotate(-270deg);
        top: -100%;
        left: -14%;
        width: 20%;
      }
    }
  }
  &-title {
    a {
      text-decoration: none;
    }
  }
  &-desc {
    h3 {
      font-size: 38px;
    }
  }
  &-subcategories {
    margin-top: 25px;
    margin-bottom: 15px;
    a {
      margin: 0 10px 10px 0;
    }
  }
  &-links {
    a {
      color: $black;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: $gold;
        text-decoration: underline;
      }
    }
  }

}


.categories-add {
  padding-top: 30px;
  padding-bottom: 60px;
}

.category-content {
  padding-top: 30px;
  overflow: hidden;
  .row {
    display: flex;
    flex-wrap: nowrap;
    @media screen and (max-width: $screen-sm) {
      flex-wrap: wrap;
    }
  }
}

.category-main-image {
  @media screen and (max-width: $screen-xs) {
    @include nopaddings;
  }
  &-wrapper {
    content: "";
    position: absolute;
    top: -30px;
    left: 0;
    height: 100%;
    width: 50vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $screen-sm) {
      position: relative;
      height: 100vh;
      width: 100%;
      top: 0;
    }
  }
}

.calculate-categories {
  margin-top: 60px;
  &-item {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item-icon {
      float: left;
      margin-right: 5%;
      flex-basis: 64px;
    }
    .item-title {
      flex-shrink: 0;
      flex-basis: 80%;
      display: flex;
      align-items: center;
      @media screen and (max-width: $screen-md) {
        flex-basis: 70%;
      }
      h3 {
        margin-top: 0;
        margin-bottom: 0;
        @media screen and (max-width: $screen-xs) {
          font-size: 24px;
        }
      }
    }
    .item-desc {
      flex-basis: 100%;
      float: none;
      clear: both;
      padding-top: 15px;
      font-size: 1em;
      p {
        margin: 0;
      }
    }
  }
  &-button {
    margin-top: 30px;
  }
}

.catalog-category,
.catalog-production {
  padding-top: 60px;
  @media screen and (max-width: $screen-md) {
    .h2 {
      font-size: 30px;
      padding-right: 270px;
    }
  }
}

// Slider
.catalog-slider {
  @media screen and (max-width: $screen-md) {
    margin-top: 30px;
  }
  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      flex-grow: 1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 520px;
      @media screen and (max-width: $screen-sm) {
        min-height: 400px;
      }
      a {
        display: block;
        color: $white;
        @include headfont;
        font-size: 2.2em;
        height: 100%;
        width: 100%;
        @include ts;
        position: absolute;
        top: 0;
        left: 0;
        padding: 30px;
        outline: 0;
        text-decoration: none;
        line-height: 1.1;
        @media screen and (max-width: $screen-sm) {
          font-size: 1.6em;
        }
        span {
          z-index: 1;
          position: relative;
        }
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          display: block;
          background-color: rgba($dark, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          transition: all 0.8s;
        }
        &:hover {
          span.category-zoom {
            display: block;
          }
          &:after {
            background-color: transparent;
          }
        }
        span.category-zoom {
          display: none;
          background-color: $gold;
          background-image: url("../img/ic-zoom-in-48-x-48-white.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 48px;
          width: 120px;
          height: 120px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }
        span.category-price {
          display: block;
          @include textfont;
          font-size: 16px;
          margin-top: 15px;
        }
        span.category-name {
          color: $white;
          font-size: 13px;
          text-transform: uppercase;
          position: absolute;
          bottom: 30px;
          left: 40px;
          transform: rotate(-90deg);
          transform-origin: top left;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.product-nav,
.catalog-nav {
  margin-top: 40px;
  @media screen and (max-width: $screen-md) {
    margin-top: -50px !important;
  }
  .owl-prev {
    display: inline-block;
    padding-left: 32px;
    margin-right: 15px;
    text-transform: uppercase;
    color: $dark;
    background-image: url("../img/ic-arrow-left-24-x-24-gold.svg");
    background-position: left center;
    background-repeat: no-repeat;
    @include textfontb;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: $dark-hover;
    }
  }
  .owl-next {
    @extend .owl-prev;
    padding-right: 32px;
    padding-left: 0;
    margin-right: 0;
    margin-left: 15px;
    background-image: url("../img/ic-arrow-right-24-x-24-gold.svg");
    background-position: right center;
  }
}

.catalog-nav {
  position: absolute;
  right: 50px;
  @media screen and (max-width: $screen-md) {
    position: relative;
    margin-top: 0;
    right: 15px;
  }
}

.description-seo {
  padding-top: 60px;
  padding-bottom: 30px;
  h1,h2,h3,h4,h5,h6,
  .h1,.h2,.h3,.h4,.h5,.h6 {
    color: $dark;
  }
}
