// Header
.header {
  height: $header-height;
  min-height: $header-height;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  @include bsf;
  position: relative;
  z-index: 50;
  &-logo {
    min-width: 320px;
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 40px;
    a {
      width: 100%;
    }
    img {
      max-width: 100%;
    }
    @media screen and (max-width: $screen-xl-max) {
      min-width: 220px;
      padding-left: 25px;
      padding-right: 25px;
    }
    @media screen and (max-width: $screen-lg) {
      min-width: 220px;
      padding-left: 15px;
    }
    @media screen and (max-width: $screen-xs) {
      flex-grow: 1;
    }
    a {}
  }

  &-phone {
    margin-right: auto;
    padding-left: 45px;
    padding-right: 15px;
    @media screen and (max-width: $screen-xl-max) {
      padding-left: 15px;
    }
    a {
      font-size: 1.25em;
      @include textfontb;
      color: $dark;
      text-decoration: none;
      @include nowrap;
      &:hover,
      &:focus {
        color: $dark;
        text-decoration: underline;
      }
    }
  }

  &-menu {
    // Первый уровень меню
    padding-right: 45px;
    @media screen and (max-width: $screen-sm) {
      display: none;
    }
    ul.menu-main {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      position: relative;
      @media screen and (max-width: $screen-lg) {
        display: none;
      }
      > li {
        list-style: none;
        margin: 0;
        padding: 0;
        a {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 0.825em;
          @include textfontb;
          padding-right: 25px;
          padding-left: 25px;
          color: $dark;
          @include nowrap;
          @media screen and (max-width: $screen-xl) {
            padding-right: 10px;
            padding-left: 10px;
          }
          &:hover {
            color: $dark;
            text-decoration: underline;
          }
          &.active {
            color: $gold;
          }
          //&[href='/calculate'] {
          //  @media screen and (max-width: $screen-xl) {
          //    display: none;
          //  }
          //}
          &[href='/production'] {
            &:after {
              content: "";
              width: 1em;
              height: 1em;
              display: inline-block;
              background-image: url("../img/arrow-down-black.svg");
              background-size: 0.8em;
              background-repeat: no-repeat;
              background-position: center;
              top: 2px;
              position: relative;
              left: 3px;
              opacity: 0.8;
            }
            &.active:after {
              transform: rotate(180deg);
            }
          }
        }

        // Второй уровень меню
        > ul {
          position: fixed;
          top: -70%;
          left: 50%;
          transform: translateX(-50%);
          padding: 40px 60px;
          display: flex;
          flex-wrap: nowrap;
          @include bs;
          margin-top: $header-height;
          visibility: hidden;
          transition: all 0.6s;
          opacity: 0;
          background: $whited;
          z-index: 1000;
          &.active {
            visibility: visible;
            top: 0;
            opacity: 1;
          }
          > li {
            a {
              color: $black !important;
              text-transform: none;
              text-decoration: underline;
              font-size: 1.4em;
              @include headfonti;
              &:hover,
              &:focus {
                color: $gold !important;
              }
            }

            // Третьий уровень меню
            > ul {
              top: inherit;
              left: inherit;
              transform: inherit;
              box-shadow: none;
              position: relative;
              margin: 10px 0 0;
              padding: 0;
              display: flex;
              flex-direction: column;
              text-align: left;
              transition: all 0s;
              background-color: transparent;
              visibility: visible;
              opacity: inherit;
              z-index: inherit;
              li {
                margin: 7px 0;
                a {
                  font-size: 1em;
                  text-decoration: none;
                  @include textfont;
                  &:hover,
                  &:focus {
                    color: $dark !important;
                  }
                }
              }
            }

          }
        }

      }
    }
  }
}



.mobile-menu {

  &-exp {
    padding: 15px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-image: url("../img/ic-hamburger-32-x-32-dark.svg");
    background-position: center;
    background-size: 32px;
    background-repeat: no-repeat;
    display: none;
    margin-right: -30px;
    @media screen and (max-width: $screen-lg) {
      display: block;
    }
    @media screen and (max-width: $screen-sm) {
      margin-right: 10px;
    }
  }

  &-wrapper {
    padding: 15px 15px 30px;
    margin: 0;
  }

  &-header {
    position: relative;
    border-bottom: 1px solid rgba($darklight, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  button.close {
    width: $header-height / 2;
    height: $header-height / 2;
    line-height: $header-height / 2;
    font-size: 1.8em;
    background: transparent;
    color: $dark;
    border: none;
    vertical-align: top;
    outline: none;
    user-select: none;
    box-sizing: border-box;
    background-image: url("../img/ic-arrow-right-24-x-24-gold.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    padding: 0;
    right: -15px;
    position: relative;
    &:hover,
    &:focus {
      background-image: url("../img/ic-arrow-right-24-x-24-gold.svg");
    }
  }

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 100%;
  overflow-y: scroll;
  background-color: $whited;
  color: $dark;
  z-index: 999;
  margin: 0;
  padding: 0;
  transition: left 0.4s;
  &.active {
    left: 0;
  }

  ul.menu-main {
    margin: 0;
    padding: 0 15px;
    > li {
      margin: 0;
      padding: 0;
      list-style: none;
      a {
        @include headfont;
        @include bigtext;
        color: $dark;
        text-decoration: none;
        display: block;
        padding: 10px 0;
        border-bottom: 1px solid rgba($darklight, 0.2);
        &:hover,
        &:focus {
          color: $gold;
        }
        &[href='/production'] {
          position: relative;
          &:after {
            content: "";
            width: 0.5em;
            height: 0.5em;
            display: inline-block;
            background-image: url("../img/arrow-down-black.svg") !important;
            background-size: 0.5em;
            background-repeat: no-repeat;
            background-position: center;
            top: 1em;
            position: absolute;
            right: 0;
            opacity: 0.8;
          }
          &.active {
            border-bottom-color: transparent;
            &:after {
              transform: rotate(180deg);
            }
          }
        }
        &[href='/calculate'] { display: none; border-bottom: 0; }
      }

      // Второй уровень
      > ul {
        margin: 10px 0 0;
        padding: 0;
        justify-content: space-between;
        display: none; //flex
        &.active {
          display: flex;
          border-bottom: 1px solid rgba($darklight, 0.2);
        }
        @media screen and (max-width: $screen-sm) {
          flex-wrap: wrap;
        }
        > li {
          margin: 0 0 20px;
          padding: 0;
          list-style: none;
          border: 0;
          flex-basis: 33%;
          padding-left: 20px;
          padding-right: 20px;
          border-right: 1px solid rgba($darklight, 0.2);
          &:last-child {
            border-right: 0;
          }
          @media screen and (max-width: 640px) {
            flex-basis: 100%;
            border-right: 0;
          }
          a {
            font-size: 1em;
            @include textfontb;
            border: 0;
          }

          // Третьий уровень
          > ul {
            margin: 10px 0 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            > li {
              margin: 0;
              padding: 0;
              list-style: none;
              border: 0;
              flex-basis: 100%;
              a {
                font-size: 0.95em;
                @include textfont;
                padding: 3px 0;
                &:before {
                  content: "•";
                  display: inline-block;
                  width: 1.5em;
                  height: 1em;
                }
              }
            }
          }

        }
      }

    }
  }

}
