// Pages customs

body.front,
body.page-node-13 {
  .scroll-up {
    display: none;
  }
}

body.page-node-1 {
  .callus {
    padding-top: 150px;
  }
  .testimonials {
    .testimonial {
      width: 50%;
      @media screen and (max-width: $screen-sm) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

body.front,
body.page-node-1,
body.page-node-14,
body.page-node-16 {
  .header {
    box-shadow: none;
    &-menu {
      ul.menu-main {
        > li {
          a {
            color: $white;
            &:hover,
            &:focus {
              color: $gold;
            }
            &[href='/production'] {
              &:after {
                background-image: url("../img/arrow-down-white.svg");
              }
            }
          }
        }
      }
    }
    a {
      color: $white;
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
  .mobile-menu-exp {
    background-image: url("../img/ic-hamburger-32-x-32-white.svg");
  }
}

body.node-type-product {
  .header {
    box-shadow: none;
    ul.menu-main {
      > li {
        a {
          color: $white;
          &:hover,
          &:focus {
            color: $gold;
          }
          &[href='/production'] {
            &:after {
              background-image: url("../img/arrow-down-white.svg");
            }
          }
        }
      }
    }
    .mobile-menu ul.menu-main {
      > li {
        a {
          color: $dark;
          &:hover,
          &:focus {
            color: $gold;
          }
        }
      }
    }
  }
  .mobile-menu-exp {
    background-image: url("../img/ic-hamburger-32-x-32-white.svg");
  }
  .catalog-production {
    padding-top: 0;
    padding-bottom: 80px;
  }
  .catalog-nav {
    position: relative;
    right: inherit;
  }
}

body.node-type-testimonial {
  main {
    padding-top: 60px;
    padding-bottom: 100px;
    min-height: calc(100vh - 408px);
    .title {
      margin-top: 0;
      color: $gold;
    }
  }
}

body.page-taxonomy-term {
  .header {
    &-logo {
      @media screen and (min-width: $screen-xl) {
        visibility: hidden;
      }
    }
  }
}

// 404
body.page-node-30 {
  main {
    padding-top: 15px;
    padding-bottom: 80px;
    text-align: center;
    h1.title {
      color: $gold;
    }
    img {
      margin: 0 auto;
    }
  }
}
