// Contacts

.contacts {
  h1.title {
    margin-bottom: 0;
  }
  &-items {

  }

  &-item {
    margin: 30px 0;
    .contact {
      &-map {
        position: relative;
        z-index: 0;
      }

      &-card {
        @include bsf;
        background-color: $white;
        color: $dark;
        max-width: 100%;
        width: 460px;
        &-wrapper {
          padding: 30px 60px;
          z-index: 1;
          position: relative;
          @media screen and (max-width: $screen-sm) {
            padding: 30px;
          }
          @media screen and (max-width: $screen-xs) {
            padding: 15px;
          }
          + ymaps {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @media screen and (max-width: $screen-xs) {
              display: none;
            }
            input {
              height: 28px;
              line-height: 28px;
            }
          }
        }
        &-photo {
          height: 215px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        &-type {
          margin: 0;
          color: $gold;
        }
        &-decor {
          height: 46px;
          max-height: 46px;
        }
        &-address,
        &-email,
        &-phone,
        &-worktime {
          padding-left: 36px;
          background-repeat: no-repeat;
          background-position: top left;
          background-size: 24px;
          margin-bottom: 20px;
          label {
            font-size: 0.85em;
            color: $darklight;
          }
        }
        &-address {
          background-image: url("../img/ic-loacation-24-x-24-gold.svg");
        }
        &-email {
          background-image: url("../img/ic-envelop-24-x-24-gold.svg");
        }
        &-phone {
          background-image: url("../img/ic-phone-24-x-24-gold.svg");
        }
        &-worktime {
          background-image: url("../img/ic-clock-24-x-24-gold.svg");
        }
      }
    }

  }

}
