// Why
.why {
  padding-top: 40px;
  padding-bottom: 60px;
  color: $white;
  background-color: $dark;
  background-image: url("../img/woodtexture_dark.png");
  background-repeat: repeat;
  background-position: top center;
  background-size: 30%;
  @media screen and (max-width: $screen-sm) {
    background-size: 90%;
  }
  &-title {
    padding-right: 60px;
    @media screen and (max-width: $screen-sm) {
      padding-right: 15px;
      p { display: none; }
    }
    &-header {
      margin-bottom: 40px;
      &:after {
        content: "";
        width: 50%;
        height: 2px;
        background-color: $gold;
        display: block;
        margin-top: 15px;
      }
      @media screen and (max-width: $screen-sm) {
        font-size: 30px;
        text-align: center;
        &:after {
          display: none;
        }
      }
    }
  }

  &-steps {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  &-step {
    flex-basis: 47%;
    padding-right: 20px;
    @media screen and (max-width: $screen-sm) {
      max-width: 50%;
      flex-basis: 50%;
      padding-left: 10px;
      padding-right: 10px;
      flex-grow: 1;
    }
    @media screen and (max-width: $screen-xs) {
      flex-basis: 100%;
      max-width: 100%;
    }
    margin-bottom: 40px;
    &-icon {
      width: 64px;
      height: 64px;
      float: left;
      img {}
    }
    &-title {
      padding-left: 90px;
      margin-top: 0;
    }
    &-desc {
      padding-left: 90px;
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }
  }

}
