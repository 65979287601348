// Front page

body.front {

  .header {
    box-shadow: none;
  }

  main {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h1.page-title {
    @media screen and (max-width: $screen-md) {
      font-size: 2.6em;
    }
  }

  .front-about {
    margin-top: 30px;
    display: flex;
    flex-wrap: nowrap;
    @media screen and (max-width: $screen-md) {
      flex-wrap: wrap;
    }
    &-video {
      flex-basis: 68%;
      position: relative;
      @media screen and (max-width: $screen-md) {
        flex-basis: 100%;
      }
      iframe {
        overflow: hidden;
        //background-image: url("../img/rectangle-3.jpg");
        //background-repeat: no-repeat;
        //background-position: center;
        //background-size: cover;
        width: 100%;
        height: 100%;
        @media screen and (max-width: $screen-md) {
          height: 320px;
        }
        @media screen and (max-width: $screen-sm) {
          height: 400px;
        }
      }
      .play-button {
        display: block;
        background-image: url("../img/play-button.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 70px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &-abilities {
      margin-left: 7%;
      flex-basis: 25%;
      @media screen and (max-width: $screen-md) {
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0;
      }
      @media screen and (max-width: $screen-xs) {
        flex-wrap: wrap;
      }
      .front-about-ability {
        margin-bottom: 10px;
        &:first-child {
          position: relative;
          top: -12px;
        }
        @media screen and (max-width: $screen-md) {
          width: 29%;
          margin: 2%;
          &:first-child {
            top: inherit;
          }
        }
        @media screen and (max-width: $screen-xs) {
          width: 96%;
          margin: 2%;
        }
        .ability-title {
          @include headfont;
          color: $gold;
          font-size: 1.8em;
          @media screen and (max-width: $screen-md) {
            text-align: center;
          }
          @media screen and (max-width: $screen-xs) {
            text-align: left;
          }
          span {
            font-size: 1.8em;
          }
        }
        .ability-desc {
          color: $dark;
          @media screen and (max-width: $screen-md) {
            text-align: center;
          }
          @media screen and (max-width: $screen-xs) {
            text-align: left;
          }
        }
      }
    }
  }

  .front-slogan {
    @include headfont;
    font-size: 1.3em;
    padding-top: 30px;
    padding-bottom: 30px;
  }

}

.front-wrapper {
  background-color: $black;
  color: $white;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  &-wrap {
    //background-image: linear-gradient(to bottom, rgba($shadow, 0.5), rgba($shadow, 0.5)), url("../img/front-bg.png");
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;
    background-color: $dark;
    color: $white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.front-socials {
  z-index: 1;
  width: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 40px;
  @media screen and (max-width: $screen-xs) {
    display: none;
  }
  a {
    margin-top: 15px;
    display: block;
  }
}
