// Footer

.footer {
  background-color: $dark;
  color: $darklight;
  padding-top: 60px;
  padding-bottom: 30px;
  position: relative;
  @media screen and (max-width: $screen-xs) {
    padding-top: 30px;
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid $gold;
    @media screen and (max-width: $screen-xs) {
      flex-wrap: wrap;
    }
  }
  a {
    color: $darklight;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $white;
      text-decoration: underline;
    }
  }

  &-logo {
    padding-right: 30px;
    &-image {
      @media screen and (max-width: $screen-xs) {
        display: none;
      }
      img {
        max-height: 52px;
      }
    }
    &-copyrights {
      padding-top: 30px;
      white-space: nowrap;
      @media screen and (max-width: $screen-sm) {
        white-space: normal;
      }
      @media screen and (max-width: $screen-xs) {
        padding-top: 0;
        padding-bottom: 30px;
      }
    }
  }

  &-menu {
    flex-grow: 1;
    padding-left: 30px;
    padding-right: 30px;
    @media screen and (max-width: $screen-md) {
      padding-left: 0;
      padding-right: 0;
    }

    // Первый уровень меню
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        flex-basis: 50%;
        list-style: none;
        margin: 0 0 15px 0;
        padding: 0;
        @media screen and (max-width: $screen-lg) {
          flex-basis: 46%;
        }
        @media screen and (max-width: $screen-xs) {
          flex-basis: 100%;
        }
        a {
          padding-right: 20px;
          padding-left: 20px;
          white-space: nowrap;
          @media screen and (max-width: $screen-sm) {
            padding-left: 0;
          }
          &.active {
            color: $white;
          }
        }

        // Второй уровень меню
        > ul {
          display: none;
          li {
            a {}
          }
        }

      }
    }
  }

  &-contacts {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-left: 30px;
    @media screen and (max-width: $screen-xs) {
      padding: 30px 15px 0;
    }

    h4 {
      margin-top: 0;
      color: $gold;
    }
    p {
      line-height: 24px;
      white-space: nowrap;
    }
    &-phone {
      &:before {
        content: "";
        background-image: url("../img/ic-phone-24-x-24-grey.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px;
        width: 24px;
        height: 24px;
        display: inline-block;
        top: 0.3em;
        position: relative;
        margin-right: 7px;
      }
    }
    &-email {
      &:before {
        content: "";
        background-image: url("../img/ic-envelop-24-x-24-gray.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px;
        width: 24px;
        height: 24px;
        display: inline-block;
        top: 0.3em;
        position: relative;
        margin-right: 7px;
      }
    }
    &-other {

    }
  }
  &-copyrights {
    padding-top: 30px;
  }

  &-socials {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: $screen-md) {
      justify-content: flex-end;
    }
    @media screen and (max-width: $screen-sm) {
      margin-left: 0;
      margin-right: 0;
      padding-right: 5px;
    }
    @media screen and (max-width: $screen-xs) {
      justify-content: flex-start;
    }
    a {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

}
