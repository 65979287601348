// Testimonials

.testimonials {
  padding-top: 30px;
  padding-bottom: 30px;
  h2, .h2 {
    margin-top: 0;
    display: flex;
    align-items: center;
    &:before {
      content: "";
      display: inline-block;
      width: 100px;
      min-width: 100px;
      height: 100px;
      background-image: url("../img/quote-big.svg");
      background-size: 100px;
      background-repeat: no-repeat;
      background-position: top 10px left;
      margin-right: 20px;
      @media screen and (max-width: $screen-md) {
        margin-right: 15px;
        width: 80px;
        min-width: 80px;
        height: 80px;
        background-size: 80px;
      }
      @media screen and (max-width: $screen-sm) {
        margin-right: 15px;
        width: 60px;
        min-width: 60px;
        height: 60px;
        background-size: 60px;
      }
    }
  }

  .testimonial {
    width: 100%;
    margin-bottom: 30px;
    background-image: url("../img/ic-quote-32-x-32.svg");
    background-size: 32px;
    background-position: top left;
    background-repeat: no-repeat;
    float: left;
    padding-left: 52px;
    padding-right: 30px;
    padding-top: 0;
    @media screen and (max-width: $screen-xs) {
      padding-right: 0;
    }
  }

  .testimonial-title {
    margin-bottom: 5px;
    margin-top: 0;
  }
  .testimonial-date {
    color: $darklight;
    margin-bottom: 10px;
  }
  .testimonial-body {}
  .testimonial-link-more {
    a {
      color: $black;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: $gold;
        text-decoration: underline;
      }
    }
  }

}

.testimonial-date {
  color: $darklight;
}


// About

.about {
  padding-top: 60px;
  padding-bottom: 80px;
  min-height: calc(75vh - 80px);
  @media screen and (max-width: $screen-sm) {
    min-height: auto;
  }
  &-us {}

  &-wrapper {
    min-height: 75vh;
    background-color: $dark;
    background-repeat: repeat, no-repeat;
    background-position: center;
    background-size: auto, cover;
    background-image: linear-gradient(to bottom, rgba($black, 0.3), rgba($black, 0.3)), url("../img/pages-bg-1.jpg");
    color: $white;
    @media screen and (max-width: $screen-sm) {
      background-position: top right 45%;
      min-height: auto;
    }
  }

  &-title {
    color: $gold;
    font-size: 3.6em;
    @media screen and (max-width: $screen-sm) {
      font-size: 48px;
      &:after { display: none; }
    }
    &:after {
      content: "";
      width: 140px;
      height: 2px;
      display: block;
      background-color: $gold;
      margin-top: 10px;
    }
  }
  &-company {
    line-height: 1.6;
  }

  &-more {
    color: $white;
    background-color: $dark;
    background-image: url("../img/woodtexture_dark.png");
    background-position: top left;
    background-repeat: repeat;
    background-size: 30%;
    padding-top: 60px;
    padding-bottom: 60px;
    @media screen and (max-width: $screen-sm) {
      background-size: 80%;
    }

    &-title {
      h3 {
        color: $gold;
        line-height: 1.4;
        margin-top: 0;
        padding-left: 96px;
        padding-right: 30px;
        background-image: url("../img/ic-production-64-x-64.svg");
        background-repeat: no-repeat;
        background-position: top 5px left;
        background-size: 64px;
        @media screen and (max-width: $screen-xs) {
          line-height: 1.2;
          background-image: none;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    &-desc-1 {
      p a {
        margin-top: 15px;
      }
    }
    &-desc-2 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &-images {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: -180px;
      @media screen and (max-width: $screen-md) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &-image {
      margin: 15px;
      @include bs;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 210px;
      height: 210px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      @media screen and (max-width: $screen-lg) {
        &:nth-child(5n) { display: none; }
      }
      @media screen and (max-width: $screen-sm) {
        width: 190px;
        height: 190px;
        margin: 15px !important;
      }
      @media screen and (max-width: $screen-xs) {
        width: 144px;
        height: 144px;
        &:nth-child(even) { margin-right: 0; }
        &:nth-child(odd)  { margin-left: 0; }
      }
    }

  }

}



