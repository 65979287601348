// Modals

.modal {
  padding-top: 0;
}
.modal-dialog {
  padding: 0;
  background-color: $whited;
  @include bs;
  margin: 0 auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.modal-lg {
    max-width: 100%;
    @media screen and (max-width: $screen-lg) {
      width: 900px;
    }
    @media screen and (max-width: $screen-md) {
      width: 720px;
    }
  }
  .links {
    text-align: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 40px;
  }
  button.close {
    position: absolute;
    top: 0;
    right: 0;
    width: $close-button;
    height: $close-button;
    line-height: $close-button;
    font-size: 1.8em;
    background: transparent;
    color: $dark;
    border: none;
    vertical-align: top;
    outline: none;
    user-select: none;
    box-sizing: border-box;
    &:hover,
    &:focus {
      color: $gold;
      &:before,
      &:after {
        color: $gold;
        background-color: $gold;
      }
    }
    &:before,
    &:after {
      color: $dark-hover;
      background-color: $dark-hover;
      pointer-events: none;
      content: "";
      display: inline-block;
      position: absolute;
      height: 2px;
      width: 24px;
      top: calc(50% - 1px);
      left: calc(50% - 12px);
      opacity: 0.9;
    }
    &:before { transform: rotate(45deg); }
    &:after  { transform: rotate(-45deg); }
  }
  .form-title {
    margin-top: 0;
    padding-top: ($header-height / 2);
    padding-left: $close-button;
    padding-right: $close-button;
    @media screen and (max-width: $screen-sm) {
      font-size: 2.2em;
    }
    @media screen and (max-width: $screen-xs) {
      font-size: 2em;
    }
  }

  .modal-description-src {
    display: none;
  }
  .form-description {
    padding-left: $close-button;
    padding-right: $close-button;
  }
  .messages {
    margin: 15px 50px 0 50px;
    line-height: 1.2;
    font-size: 0.8em;
  }
  .form-item .messages {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .ajax-new-content {
    display: none !important;
  }
  .webform-confirmation {
    //display: none;
    padding: ($header-height / 2) $header-height;
    text-align: center;
    color: $gold;
    font-size: 1.2em;
  }

  .webform-component-file {
    .description {
      display: none;
    }
  }

  form {
    padding: 0 $close-button $close-button $close-button;
    .form-actions {
      margin-bottom: 0;
    }
  }

}
.modal-backdrop {
  &.in {
    background-color: $black;
    opacity: 0.8;
  }
}

.modal {
  .webform-client-form-18 {
    .webform-component--calculate-email {
      display: none;
    }
  }
}
