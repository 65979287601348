// Call Us
.callus {
  padding-top: 60px;
  padding-bottom: 60px;
  @media screen and (max-width: $screen-sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.callus-desc {
  h2 {
    font-size: 2em;
    @media screen and (max-width: $screen-sm) {
      font-size: 1.6em;
      line-height: 1.2;
    }
  }
  p:nth-child(1),
  p:nth-child(2) {
    margin-bottom: 25px;
  }
  a[href^='tel:'] {
    @include textfontb;
    font-size: 1.3em;
    color: $dark;
    height: 48px;
    line-height: 48px;
    &:before {
      content: "";
      display: block;
      float: left;
      width: 48px;
      height: 48px;
      background-image: url("../img/ic-smartphone.svg");
      background-position: center;
      background-size: 48px;
      background-repeat: no-repeat;
      margin-right: 15px;
    }
    &:hover,
    &:focus {
      color: $dark;
      text-decoration: underline;
    }
  }
}

// Callback
.callback {
  padding-top: 40px;
  .webform-client-form {
    .form-actions {
      input[type="submit"] {
        width: 100% !important;
      }
    }
  }
  .webform-component--callback-name,
  .webform-component--callback-phone {
    @media screen and (max-width: $screen-sm) {
      width: 50%;
      float: left;
    }
    @media screen and (max-width: $screen-xs) {
      width: 100%;
      float: none;
      padding: 0 !important;
    }
  }
  .webform-component--callback-name {
    @media screen and (max-width: $screen-sm) {
      padding-right: 10px;
    }
  }
  .webform-component--callback-phone {
    @media screen and (max-width: $screen-sm) {
      padding-left: 10px;
    }
  }
}
