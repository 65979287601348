// variables

$header-height: 90px;
$sidebar-width: 300px;
$close-button: $header-height / 1.5;

$button: 52px;
$button-sm: 36px;
$socials: 24px;

// colors

$gold: #c6a47c;
$gold-hover: #e0ba8d;
$golddark: #745938;

$dark: #2b2b2b;
$dark-hover: #454545;
$darklight: #959595;

$black: #000;
$white: rgba(#fff, 0.925);
$whited: #fff;

$shadow: #0f0300;


// fonts

@font-face {
  font-family: 'Oranienbaum';
  src: url('../fonts/Oranienbaum.eot');
  src: url('../fonts/Oranienbaum.woff') format('woff'),
  url('../fonts/Oranienbaum.ttf') format('truetype'),
  url('../fonts/Oranienbaum.otf') format('opentype'),
  url('../fonts/Oranienbaum.svg#Oranienbaum') format('svg'),
  url('../fonts/Oranienbaum.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}


// animations

$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275); //cubic-bezier(0.175, 0.885, 0.32, 1.275);


// mixins

@mixin br {
  border-radius: 16px;
}
@mixin bri {
  border-radius: 4px;
}
@mixin br50 {
  border-radius: 50%;
}
@mixin br21 {
  border-radius: 21px;
}

@mixin headfont {
  font-family: 'Oranienbaum', serif;
  font-weight: normal;
  letter-spacing: 0.5px;
  //text-transform: uppercase;
}
@mixin headfonti {
  font-family: 'Oranienbaum', serif !important;
  font-weight: normal;
  letter-spacing: 0.5px;
}
@mixin textfont {
  font-family: "Roboto", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
  font-weight: 400;
  letter-spacing: 0.5px;
}
@mixin textfontb {
  font-family: "Roboto", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
  font-weight: 500;
  letter-spacing: 0.5px;
}
@mixin middletext {
  font-size: 1.125em;
}
@mixin bigtext {
  font-size: 1.4em;
}
@mixin biggtext {
  font-size: 2em;
}

@mixin nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ts {
  text-shadow: 1px 1px 1px rgba($black, 0.3);
}
//@mixin tsf {
//  text-shadow: 1px 1px 1px $black;
//}
//
@mixin bs {
  box-shadow: 0 4px 20px rgba($black, 0.3);
}
@mixin bsf {
  box-shadow: 0 1px 6px rgba($black, 0.15);
}

@mixin darktexture {
  background-image: url("../img/woodtexture_dark.png");
  background-repeat: repeat;
  background-position: top center;
  background-color: $black;
  color: $white;
}

@mixin lighttexture {
  background-image: url("../img/woodtexture_light.png");
  background-repeat: repeat;
  background-position: top center;
  background-color: $white;
  color: $black;
}

@mixin nopaddings {
  padding-left: 0 !important;
  padding-right: 0 !important;
}


// media queries
$screen-xxs: 512px;

$screen-xl: 1600px;
$screen-xl-max: $screen-xl - 1;
$screen-xl-min: $screen-xl + 1;

$screen-xxl: 1800px;


// krumo

.krumo-root {
  position: relative;
  top: 15px;
  left: 0;
  width: 100%;
  z-index: 9999;
}
