// Partners
.partners {

  .row {
    @media screen and (max-width: $screen-sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .container {
    @media screen and (max-width: $screen-sm) {
      height: 615px;
    }
    @media screen and (max-width: $screen-xs) {
      height: 680px;
    }
  }

  &-wrapper{
    background-color: $dark;
    color: $white;
    background-image: url("../img/woodtexture_dark.png");
    background-position: top left;
    background-repeat: repeat;
    background-size: 30%;
    @media screen and (max-width: $screen-sm) {
      background-size: 80%;
    }
  }
  &-main {
    padding-top: 60px;
    padding-bottom: 60px;
    @media screen and (max-width: $screen-sm) {
      order: 1;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &-title {
    color: $gold;
    font-size: 3.6em;
    @media screen and (max-width: $screen-sm) {
      font-size: 3em;
      margin-top: 0;
    }
    @media screen and (max-width: $screen-xs) {
      font-size: 2.6em;
    }
  }
  &-desc {}

  &-form {
    @media screen and (max-width: $screen-sm) {
      order: 2;
      padding-bottom: 30px;
    }

      &-wrapper {
      position: absolute;
      left: 0;
      width: 100%;
      margin: 30px 15px 0;
      padding: 40px 60px;
      background-color: $whited;
      color: $dark;
      z-index: 10;
      @include bsf;
      @media screen and (max-width: $screen-sm) {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
      }
      .ajax-new-content {
        display: none !important;
      }
      .webform-component--calculate-name,
      .webform-component--calculate-phone-email {
        @media screen and (max-width: $screen-sm) {
          width: 50%;
          float: left;
        }
        @media screen and (max-width: $screen-xs) {
          width: 100%;
          float: none;
          padding: 0 !important;
        }
      }
      .webform-component--calculate-name {
        @media screen and (max-width: $screen-sm) {
          padding-right: 10px;
        }
      }
      .webform-component--calculate-phone-email {
        @media screen and (max-width: $screen-sm) {
          padding-left: 10px;
        }
      }

      .form-title {}
      .webform-component-file {
        .description {
          display: none;
        }
      }
      .form-actions {
        margin-top: 20px;
        margin-bottom: 0;
        .form-submit {
          width: 100%;
        }
      }
    }
    &-desc {
      font-size: 0.95em;
    }
  }
}

.privileges {
  padding-top: 230px; //60px
  padding-bottom: 80px;
  @media screen and (max-width: $screen-sm) {
    padding-top: 30px;
  }
  @media screen and (max-width: $screen-sm) {
    padding-top: 270px;
  }
  @media screen and (max-width: $screen-xs) {
    padding-top: 440px;
  }
  &-title {
    @media screen and (max-width: $screen-xs) {
      font-size: 2.4em;
      text-align: center;
    }
    &:after {
      content: "";
      display: inline-block;
      height: 1em;
      width: 140px;
      background-image: url("../img/decor-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      top: 0.25em;
      position: relative;
      left: 30px;
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }
  }
  &-items {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    flex-basis: 47%;
    padding: 15px;
    display: flex;
    flex-wrap: nowrap;
    @media screen and (max-width: $screen-xs) {
      padding-left: 0;
      padding-right: 0;
      flex-basis: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    img {
      width: 64px;
      min-width: 64px;
      height: 64px;
      margin-right: 30px;
      @media screen and (max-width: $screen-xs) {
        margin: 0 0 15px 0;
      }
    }
    p {
      padding-right: 15px;
      @media screen and (max-width: $screen-xs) {
        text-align: center;
        padding-right: 0;
      }
    }
  }
}
