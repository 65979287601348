// Forms

form {
  .form-item {
    margin-top: 0;
    margin-bottom: 0.35em;
    label {
      @include textfontb;
      color: $darklight;
      font-size: 0.95em;
      margin-bottom: 3px;
      span.form-required {
        color: $darklight;
      }
    }
    input {
      &[name="submitted[callback_name]"],
      &[name="submitted[callback_phone]"],
      &[name="submitted[calculate_email]"],
      &[name="submitted[calculate_name]"],
      &[name="submitted[calculate_phone_email]"] {
        padding-right: 55px;
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-size: 24px;
        @media screen and (max-width: $screen-sm) {
          width: 100%;
        }
      }
      &[name="submitted[callback_name]"],
      &[name="submitted[calculate_name]"] {
        background-image: url("../img/ic-user-24-x-24-gold.svg");
      }
      &[name="submitted[callback_phone]"],
      &[name="submitted[calculate_phone_email]"] {
        background-image: url("../img/ic-phone-24-x-24-gold.svg");
      }
      &[name="submitted[calculate_email]"] {
        background-image: url("../img/email-icon-24-x-24.svg");
      }

    }
  }
  .form-actions {
    margin-top: 30px;
    @media screen and (max-width: $screen-sm) {
      display: flex;
      justify-content: center;
    }
    .form-submit {
      width: 100%;
      @media screen and (max-width: $screen-sm) {
        width: auto !important;
      }
    }
  }
}
.form-description {
  font-size: 1em;
  @include textfont;
}
.form-textarea-wrapper {
  textarea {
    //height: 90px;
  }
}

label {
  //font-weight: 500;
  @include textfontb;
}

.webform-component-file {
  .form-managed-file {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gold;
    line-height: 48px;
    padding: 12px 15px;
    .messages {
      margin: 0;
      width: 100%;
      font-size: 0.725em;
      flex-basis: 100%;
    }
    .file {
      @include nowrap;
      padding-left: 15px;
      padding-right: 0;
      flex-basis: calc(100% - 60px);
      &-icon {
        margin-right: 5px;
      }
    }
    .form-file {
      flex-basis: 100%;
      width: 100%;
      border: 0;
      line-height: 1em;
      min-height: auto;
      padding: 0;
    }
    .description {
      display: none;
      color: $darklight;
      font-size: 0.8em;
      line-height: 1.1;
      margin-top: 10px;
    }
    input[name="submitted_calculate_file_upload_button"],
    input[name="submitted_partner_file_upload_button"] {
      display: none;
    }
    input[name="submitted_partner_file_remove_button"],
    input[name="submitted_calculate_file_remove_button"] {
      text-indent: -9999em;
      background-image: url("../img/ic-close-24-x-24-grey.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      height: 48px;
      line-height: 48px;
      flex-basis: 60px;
      background-color: transparent;
      border: 0;
    }
  }
}

.messages {
  font-size: 0.9em;
}
