/**
 * SuperBox
 * The lightbox reimagined. Fully responsive HTML5 image galleries.
 *
 * Latest version: https://github.com/seyDoggy/superbox
 * Original version: https://github.com/toddmotto/superbox
 *
 * License <https://github.com/seyDoggy/superbox/blob/master/LICENSE.txt>
 */
.superbox-active {
  word-spacing: 0;
  letter-spacing: 0;
  font-size: 0;
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    word-spacing: normal;
    letter-spacing: normal;
    font-size: 16px;
  }

  .superbox-list {
    display: inline-block;
    width: 11.5%;
    zoom: 1;
    vertical-align: bottom;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -16px;
      left: calc(50% - 30px);
      border: 30px solid transparent;
      border-bottom: 0;
      transition: all 0.3s;
      visibility: hidden;
    }
    &.active:after {
      visibility: visible;
      border-bottom: 30px solid $dark;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }

  .superbox-show {
    position: relative;
    display: none;
    float: left;
    padding: 30px 20px 30px;
    width: 100% !important;
    background-color: $dark;
    height: 0;
    transition: all ease-in-out;
    justify-content: center;
    //box-shadow: inset 0 1px 10px $black;
    margin-bottom: 15px;
    @media screen and (max-width: $screen-md) {
      flex-wrap: wrap;
      padding-top: 54px;
    }
    &-wrapper {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    .superbox-current-link {
      width: auto;
      max-width: 55%;
      height: auto;
      max-height: 500px;
      @media screen and (max-width: $screen-md) {
        max-width: 100%;
        padding-right: 0;
      }
    }

    img.superbox-current-img {
      width: auto;
      //max-width: 55%;
      height: auto;
      max-height: 500px;
      opacity: 0;
      flex-basis: auto;
      object-fit: cover;
      @media screen and (max-width: $screen-md) {
        max-width: 100%;
        padding-right: 0;
      }
    }

    .item-wrapper {
      width: auto;
      height: 100%;
      opacity: 0;
      color: $white;
      flex-basis: auto;
      margin-left: 50px;
      max-width: 350px;
      text-align: left;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: $screen-md) {
        margin-left: 0;
        width: 100%;
        flex-basis: 100%;
        height: auto;
        justify-content: flex-start;
        margin-top: 30px;
        max-width: 100%;
      }
      @media screen and (max-width: $screen-sm) {
        flex-wrap: wrap;
        flex-direction: row;
      }

      .item-title {
        margin-top: 0;
        @media screen and (max-width: $screen-sm) {
          width: 100%;
          padding-left: 15px;
          padding-right: 45px;
        }
        a {
          text-decoration: none;
          color: $gold;
          line-height: 1;
          @include headfont;
          @include biggtext;
          &:hover {
            color: $gold-hover;
          }
        }
      }
      .item-description {
        color: $white;
        font-size: 0.9em;
        margin-bottom: 15px;
        @media screen and (max-width: $screen-sm) {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .item-material,
      .item-cover,
      .item-items,
      .item-prices {
        color: $darklight;
        font-size: 0.95em;
        padding-left: 15px;
        line-height: 1.25;
        margin-bottom: 5px;
        padding-bottom: 10px;
        border-bottom: 1px dashed $dark-hover;
        @media screen and (max-width: $screen-sm) {
          width: 50%;
          padding-right: 30px;
        }
        @media screen and (max-width: $screen-xs) {
          width: 100%;
          padding-right: 30px;
          margin-bottom: 10px;
        }
        &:before {
          content: "•";
          display: inline-block;
          width: 1em;
          height: 1em;
        }
      }
      .item-buttons {
        margin-top: 15px;
        @media screen and (max-width: $screen-sm) {
          width: 100%;
        }
        a {
          text-align: center;
          width: 100%;
          @media screen and (max-width: $screen-sm) {
            width: 45%;
            margin-left: 15px;
            margin-right: 15px;
          }
          @media screen and (max-width: $screen-sm) {
            width: 92.5%;
            margin-left: 15px;
            margin-right: 15px;
          }
          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }

  }

  .superbox-close,
  .superbox-prev,
  .superbox-next {
    position: absolute;
    width: 30px;
    height: 30px;
    color: #fff;
    text-decoration: none;
    opacity: 0;
    cursor: pointer;
  }
  .superbox-close {
    top: 0;
    right: 0;
    position: absolute;
    width: $close-button;
    height: $close-button;
    line-height: $close-button;
    font-size: 1.8em;
    background: transparent;
    color: $whited;
    border: none;
    vertical-align: top;
    outline: none;
    user-select: none;
    box-sizing: border-box;
    &:hover,
    &:focus {
      color: $gold;
      &:before,
      &:after {
        color: $gold;
        background-color: $gold;
      }
    }
    &:before,
    &:after {
      color: $whited;
      background-color: $whited;
      pointer-events: none;
      content: "";
      display: inline-block;
      position: absolute;
      height: 2px;
      width: 24px;
      top: calc(50% - 1px);
      left: calc(50% - 12px);
      opacity: 0.9;
    }
    &:before { transform: rotate(45deg); }
    &:after { transform: rotate(-45deg); }
  }

}
.superbox-active .superbox-prev,
.superbox-active .superbox-next {
  top: 50%;
  margin-top: -15px;
}
.superbox-active .superbox-prev {
  left: 10px;
}
.superbox-active .superbox-next {
  right: 10px;
}
.superbox-active .superbox-show > a > i {
  font-size: 30px;
}
.superbox-active .superbox-close:hover,
.superbox-active .superbox-prev:hover,
.superbox-active .superbox-next:hover {
  text-decoration: none;
  opacity: 1 ! important;
}
.superbox-active .superbox-float {
  float: left;
}

/*
 * Resonsive Rules
 */
.superbox-active .superbox-2 {
  width: 48.75%;
}
.superbox-active .superbox-3 {
  width: 31.65%;
}
.superbox-active .superbox-4 {
  width: 23.85%;
}
.superbox-active .superbox-5 {
  width: 18.8%;
}
.superbox-active .superbox-6 {
  width: 15.83%;
}
.superbox-active .superbox-7 {
  width: 16.667%;
}
.superbox-active .superbox-8 {
  width: 12%;
}

/*
 * Font icons
 */
@font-face {
  font-family: 'SuperBoxIcons';
  src: url('../fonts/superboxicons.eot?6890439');
  src: url('../fonts/superboxicons.eot?6890439#iefix') format('embedded-opentype'),
  url('../fonts/superboxicons.woff?6890439') format('woff'),
  url('../fonts/superboxicons.ttf?6890439') format('truetype'),
  url('../fonts/superboxicons.svg?6890439#superboxicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*  Font Awesome styles
    ------------------------------------------------------- */
.superbox-active [class^="icon-"],
.superbox-active [class*=" icon-"] {
  font-family: SuperBoxIcons;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
}
.superbox-active [class^="icon-"]:before,
.superbox-active [class*=" icon-"]:before {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-decoration: inherit;
  display: inline-block;
  speak: none;
}
/* makes sure icons active on rollover in links */
.superbox-active a [class^="icon-"],
.superbox-active a [class*=" icon-"] {
  display: inline-block;
}
.superbox-active .icon-remove-sign:before { content: "\2716"; }
.superbox-active .icon-circle-arrow-left:before { content: "\e4a1"; }
.superbox-active .icon-circle-arrow-right:before { content: "\e4a2"; }

