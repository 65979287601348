html {}

body {
  color: $dark;
  background-color: $white;
  @include textfont;
}

// Outline fix
a:hover, a:active, a:focus, a:link, a:visited {
  outline: 0 !important;
  outline-style:none;
}
button, object, embed {
  outline: 0;
}
/* All Input elements */
input::-moz-focus-inner {
  outline: 0;
}
/* Or more specifically*/
input[type="submit"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner{
  outline: 0;
}
// Outline fix

::-webkit-scrollbar { /* for Mac OS X support */
  width: 0;
}

.gold { color: $gold; }
.gold-dark { color: $golddark; }
.dark { color: $dark; }
.dark-light { color: $darklight; }

.hidden-xxs {
  @media screen and (max-width: $screen-xxs) {
    display: none !important;
  }
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
  @include headfont;
}

h1, .h1 {
  font-size: 48px;
  @media screen and (max-width: $screen-sm) {
    font-size: 38px;
  }
  @media screen and (max-width: $screen-xs) {
    font-size: 36px;
  }
  &.page-title {
    text-align: center;
    &:before {
      content: "";
      display: inline-block;
      width: 145px;
      height: 42px;
      background-image: url("../img/decor.svg");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(180deg);
      margin-bottom: -7px;
      margin-right: 25px;
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }
    &:after {
      content: "";
      display: inline-block;
      width: 145px;
      height: 42px;
      background-image: url("../img/decor.svg");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: -7px;
      margin-left: 25px;
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
    }
  }
}
h2, .h2 {
  font-size: 38px;
  margin-top: 30px;
  @media screen and (max-width: $screen-sm) {
    font-size: 30px;
  }
  @media screen and (max-width: $screen-xs) {
    font-size: 34px;
  }
}
h4, .h4 {
  font-size: 21px;
  margin-top: 22px;
}

a {
  color: $dark;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: $gold;
  }
  &.small-button {
    background-color: $gold;
    color: $white;
    text-transform: uppercase;
    font-size: 0.8em;
    line-height: 24px;
    height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    @include br;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    &:hover,
    &:focus {
      background-color: $black;
    }
  }
}

b,
strong {
  @include textfontb;
}


// Buttons

input,
textarea {
  border: 1px solid $gold;
  min-height: $button - 4;
  max-width: 100%;
  background-color: transparent;
  color: $dark;
  padding-left: 15px;
  padding-right: 15px;
  &.progress-disabled,
  &[disabled] {
    filter: grayscale(1);
    cursor: not-allowed !important;
  }
}

textarea {
  padding-top: 10px;
  padding-bottom: 10px;
}

.button {
  line-height: $button;
  height: $button;
  color: $white;
  border-width: 0 0 3px 0;
  border-bottom-color: $golddark;
  background-color: $gold;
  display: inline-block;
  padding: 0 30px;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 0.5px;
  font-size: 1em;
  @include textfontb;
  text-decoration: none;
  white-space: nowrap;
  max-width: 100%;
  &:after {
    bottom: 0;
    left: 0;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: $golddark;
  }
  &:hover,
  &:focus {
    background-color: $gold-hover;
    color: $white;
    text-decoration: none;
  }

  &-black {
    background-color: $dark;
    &:after {
      background-color: $black;
    }
    &:hover,
    &:focus {
      background-color: $dark-hover;
      &:after {
        background-color: $black;
      }
    }
  }
  &-transparent {
    @extend .button;
    background-color: transparent;
    border: 4px solid rgba($white, 0.5);
    line-height: 46px;
    @media screen and (max-width: $screen-xs) {
      line-height: 36px !important;
      height: 44px !important;
    }
    &:after {
      display: none;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      border-color: $gold-hover;
      color: $gold-hover;
    }
  }
  @media screen and (max-width: $screen-xs) {
    padding: 0 20px;
    font-size: 0.925em;
    line-height: ($button / 1.25);
    height: ($button / 1.25);
  }

}
input[type="submit"] {
  @extend .button;
}


// Scrolls

.scroll-up,
.scroll-down {
  width: 140px;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  a {
    opacity: 0.8;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../img/scroll-down.svg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    transition: all 0.4s;
    &:hover {
      opacity: 1;
    }
  }
  @media screen and (max-width: $screen-xs) {
    display: none;
  }
}
.scroll-up {
  top: -70px;
  bottom: inherit;
  a {
    background-image: url("../img/scroll-up.svg");
  }
}


// Breadcrumb

.breadcrumb {
  font-size: 0.8em;
  a {
    color: $gold;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $dark;
      text-decoration: underline;
    }
  }
  &-arrow {
    height: 1em;
    width: 1em;
    display: inline-block;
    background-image: url("../img/ic-arrow-right-16-x-16-gold.svg");
    background-repeat: no-repeat;
    background-size: 1em;
    background-position: top 2px center;
    margin-left: 2px;
    margin-right: 2px;
  }
}

// Ajax throbber
// .ajax-progress-throbber {}
.ajax-progress-throbber .message {
  display: none;
}
.ajax-progress-throbber .throbber {
  background-image: url('../img/preloader.svg');
}
.ajax-progress .throbber {
  background: url('../img/preloader.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
  width: 48px;
  height: 48px;
  display: block;
  position: fixed;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  z-index: 9999;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
