// Calculate
.calculate {
  background-image: url("../img/woodtexture_light.png");
  background-repeat: repeat;
  background-position: top center;
  background-size: 20%;
  border-top: 1px solid $gold;
  @media screen and (max-width: $screen-sm) {
    background-size: 70%;
  }
  > .container {
    position: relative;
    &:after {
      content: "";
      background-image: url("../img/calculate.png");
      background-position: right 30px bottom;
      background-repeat: no-repeat;
      background-size: 35%;
      height: 120%;
      width: 100%;
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      @media screen and (max-width: $screen-lg) {
        background-size: 46%;
      }
      @media screen and (max-width: $screen-md) {
        background-size: 54%;
        right: -40px;
      }
      @media screen and (max-width: $screen-sm) {
        display: none;
      }
      @media screen and (max-width: $screen-xs) {
        display: none;
      }
    }
  }

  &-wrapper {
    padding-top: 60px;
    padding-bottom: 80px;
    position: relative;
    @media screen and (max-width: $screen-sm) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &-title {
    margin-bottom: 40px;
    @media screen and (max-width: $screen-sm) {
      margin-top: 0;
    }
  }
  &-desc {}
  &-button {
    margin-top: 40px;
    z-index: 2;
    position: relative;
    a {

    }
  }

}
